a {
    text-decoration: none;
    color: red;
}

.home__container {
    width: 100%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title__head {
    margin-bottom: 0;
    display:flex;
    align-items: center;
}

.subtitle {
    margin: 1% 0;
}

.subscript {
    font-size: 11px;
    margin-bottom: 15%;
}

.song__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}

.song__item {
    margin: 5px 0;
}

.word__section {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: auto 5%;
}

.album__command {
    text-align: center;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.disclaimer {
    font-size: small;
    margin: 10% 0 0 0;
    text-align: center;
}

.github {
    font-size: small;
    margin: 0;
}

.github__link {
    text-decoration: underline;
    color: black;
    margin: 0;
}

@media screen and (max-width: 950px) {

    img {
        width: 150px;
        height: 150px;
    }
    .home__container {
        margin-top: 2%;
    }

    .title__head {
        font-size: large;
    }

    .word__section {
        font-size: small;
        margin-bottom: 0;
    }

    .song__item {
        margin: 0;
    }

    .album__command {
        margin: 0;
    }

    .song__info {
        margin-top: 0;
    }
}